<template>
  <div class="gdInfo">
    <div class="myDiv" v-if="data.hasOwnProperty('requestUser')">
      <div class="flexLR">
        <div>发起人：</div>
        <div>{{ data.requestUser }}</div>
      </div>
      <div class="flexLR">
        <div>任务名称：</div>
        <div>{{ data.wkod02 }}</div>
      </div>
      <div class="flexLR">
        <div>任务内容：</div>
        <div>{{ data.wkod03 }}</div>
      </div>
      <div class="flexLR">
        <div>计划时间：</div>
        <div>{{ data.wkod09 }}</div>
      </div>
      <div class="flexLR">
        <div>工作标签：</div>
        <div>{{ data.wkod12 }}</div>
      </div>
      <div class="flexLR">
        <div>重要程度：</div>
        <div>
          <van-field name="rate">
            <template #input>
              <van-rate readonly v-model="data.wkod06" />
            </template>
          </van-field>
        </div>
      </div>
      <div class="flexLR" style="justify-content: flex-start">
        <div>紧急程度：</div>
        <div>
          <van-field name="rate">
            <template #input>
              <van-rate readonly v-model="data.wkod07" />
            </template>
          </van-field>
        </div>
      </div>
    </div>

    <div class="myDiv">
      关联模型：
      <table>
        <thead>
          <tr>
            <td>序号</td>
            <td>名称</td>
            <td>类型</td>
            <td>截图</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data.modelselectList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type == "1" ? "选择集" : "视点" }}</td>
            <td><van-icon name="photo-o" size="18" /></td>
            <td>
              <van-icon
                name="eye-o"
                @click="modelClick(item)"
                size="18"
                color="#00a1ff"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="myDiv">
      附件：
      <table>
        <thead>
          <tr>
            <td>序号</td>
            <td>名称</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data.WorkattachmentList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.prfl02 }}</td>
            <td>
              <van-icon
                name="eye-o"
                size="18"
                @click="wxClick(item)"
                color="#00a1ff"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="myDiv" v-if="data.hasOwnProperty('requestUser')">
      <div class="flexLR">
        <div>执行人：</div>
        <div>{{ data.executeUser }}</div>
      </div>
      <div class="flexLR">
        <div>状态：</div>
        <div :style="{ color: data.state != '进行中' ? 'red' : '#00adff' }">
          {{ data.state }}
        </div>
      </div>
      <div class="flexLR">
        <div>时间范围：</div>
        <div>{{ data.wkod04 }} 至 {{ data.wkod05 }}</div>
      </div>
      <div class="flexLR">
        <div>计划时间：</div>
        <div>{{ data.wkod09 }}</div>
      </div>
      <div class="flexLR" style="width: 100%">
        <div>进度：</div>
        <van-progress
          style="width: 63%; margin: 0 2%"
          :percentage="data.wkod18 || 0"
        />
        <van-button type="info" size="mini" @click="jdClick"
          >进度汇报</van-button
        >
      </div>
    </div>
    <div class="myDiv">
      记录：
      <table>
        <thead>
          <tr>
            <td>时间</td>
            <td>进度</td>
            <td>内容</td>
            <td>附件</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data.progressList" :key="index">
            <td>{{ item.woli07.substr(0, item.woli07.indexOf(" ")) }}</td>
            <td>{{ item.woli03 }}%</td>
            <td class="nowrap" style="width: 120px; display: block">
              {{ item.woli04 }}
            </td>
            <td>
              <van-icon
                name="eye-o"
                @click="wxClick(item)"
                size="18"
                color="#00a1ff"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="myDiv" v-if="data.hasOwnProperty('requestUser')">
      <div class="flexLR">
        <div>审核人：</div>
        <div>{{ data.executeUser }}</div>
      </div>
      <div class="flexLR">
        <div>状态：</div>
        <div :style="{ color: data.state != '进行中' ? 'red' : '#00adff' }">
          {{ data.state }}
        </div>
      </div>
      <div class="flexLR">
        <div>截止时间：</div>
        <div>{{ data.wkod09 }}</div>
      </div>
      <div class="flexLR">
        <div>验收完成：</div>
        <div>{{ data.woli07 }}</div>
      </div>
      <div class="flexLR" style="width: 100%">
        <div>验收意见：</div>
        <div style="width: 65%">{{ data.woli04 }}</div>
        <!-- <van-button type="info" size="mini">验收</van-button> -->
      </div>
    </div>
    <div class="myDiv">
      附件：
      <table>
        <thead>
          <tr>
            <td>序号</td>
            <td>名称</td>
            <td>附件</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data.attachmentList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.atta03 }}</td>
            <td><van-icon name="eye-o" size="18" color="#00a1ff" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <van-dialog
      v-model="show"
      title="进度汇报"
      @confirm="HandelWorkOrder"
      @cancel="show = false"
      show-cancel-button
      :confirmButtonText="ButtonName"
    >
      <div style="padding: 40px">
        <div style="height: 20px">
          <van-slider
            v-model="form.woli03"
            bar-height="4px"
            active-color="#ee0a24"
            :min="data.wkod18"
            @change="changeSlider"
          >
            <template #button>
              <div class="custom-button">{{ form.woli03 }}%</div>
            </template>
          </van-slider>
        </div>
        <div>
          <van-field
            v-model="form.woli04"
            rows="4"
            autosize
            type="textarea"
            maxlength="100"
            label="成果说明"
            label-width="60"
            show-word-limit
            placeholder="请输入内容"
          />
          <van-uploader v-model="fileList" multiple :max-count="1" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  GetWorkOrderInfo,
  HandelWorkOrder,
  UpdateWorkOrderTime,
  GetSelectOrView,
} from "@/api";
export default {
  name: "gdInfo",
  components: {},
  data() {
    return {
      fileList: [],
      ButtonName: "提交",
      show: false,
      data: {},
      form: {
        woli02: "",
        woli03: "",
        woli04: "",
        woli05: 1,
        woli06: this.$store.state.uid,
        woli08: 1,
        xid: this.$store.state.pid,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    modelClick(item) {
      // 查看模型
      let params = {
        id: item.id,
        type: item.type,
      };
      GetSelectOrView(params).then((res) => {
      this.$router.push({ path: "/model", query: { modelId: res.Data.modelId,state:res.Data.state } });
      });
    },
    changeSlider(v) {
      if (v == 100) {
        this.ButtonName = "申请审核";
      } else {
        this.ButtonName = "提交";
      }
    },
    jdClick() {
      this.show = true;
      this.form.woli02 = this.data.wkod01;
      this.form.woli03 = this.data.wkod18;
      // this.form.woli06 = this.data.wkod11;
    },
    wxClick(item) {
      let data = {
        imgList: [],
        fileList: [],
      };
      let type;
      if (item.hasOwnProperty("prfl04")) {
        type = item.prfl04.substr(item.prfl04.indexOf(".") + 1);
        if (type == "png" || type == "jpg" || type == "jpeg") {
          data.imgList.push(this.$store.state.baseUrl + item.prfl04);
        } else {
          data.fileList.push(this.$store.state.baseUrl + item.prfl04);
        }
      } else {
        type = item.atta05.substr(item.atta05.indexOf(".") + 1);
        if (type == "png" || type == "jpg" || type == "jpeg") {
          data.imgList.push(this.$store.state.baseUrl + item.atta05);
        } else {
          data.fileList.push(this.$store.state.baseUrl + item.atta05);
        }
      }
      wx.miniProgram.navigateTo({
        url: `/pages/download/index?data=` + JSON.stringify(data),
      });
    },
    updateData() {
      this.$emit("updateLoading");
      this.getData();
    },
    async HandelWorkOrder() {
      let formData = { ...this.form };
      if (this.fileList.length > 0) {
        formData = new FormData();
        for (const key in this.form) {
          formData.append(key, this.form[key]);
        }
        formData.append("file", this.fileList[0].file);
      }
      let res = await HandelWorkOrder(formData);
      res.Code > 0 ? this.$Toast("成功") : this.$Toast("失败");

      if (this.ButtonName == "申请审核") {
        let params = {
          wid: this.form.woli02,
          type: 2,
        };
        let res2 = await UpdateWorkOrderTime(params);
        console.log(res2);
        this.$router.push({ path: "/home/menu/backlog", query: { active: 2 } });
      }
      this.getData();
    },
    async getData() {
      let res = await GetWorkOrderInfo({
        params: {
          wid: this.$route.query.id,
        },
      });
      console.log(res);
      this.data = res.Data;
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.gdInfo {
  min-height: 100vh;
  padding: 100px 40px;
  .flexLR {
    margin: 20px 10px;
    // padding:5px 0;
    justify-content: flex-start;
  }
  .myDiv {
    margin-top: 20px;
  }
  table {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    td {
      padding: 10px;
    }
  }
  .custom-button {
    width: 85px;
    color: #fff;
    font-size: 10px;
    // padding: 5px 15px;
    // line-height: 18px;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 100px;
  }
}
</style>